<template>
    <div>
        <div class="deal-card" v-loading="loading" >
            <div style="height: 30px">
                <!-- <el-col :span="24" style="padding: 0" class="bg-dark"><div class="bg-dark-div bg-dark-top"></div></el-col> -->
                <div style="margin-left: 20px;margin-bottom: 10px;display: inline-block;float: left;"><i class="card-line"></i>五档行情</div>
                <div style="float: right;color: #03032C; font-size: 14px;margin-right: 30px;">
                  <span style="">今开：{{Object.keys(hqData).length === 0?'-':hqData.newOpen}}</span>
                  <span style="padding-left: 20px;">{{codeType===3?'昨结':'昨收'}}：{{Object.keys(hqData).length === 0?'-':hqData.lastClose}}</span>
                  <span style="padding-left: 20px;">最高：{{Object.keys(hqData).length === 0?'-':hqData.upPrice}}</span>
                  <span style="padding-left: 20px;">最低：{{Object.keys(hqData).length === 0?'-':hqData.downPrice}}</span>
                </div>
                
            </div>
            <div style="padding: 10px 20px;margin: 10px 0">
              <el-row :gutter="20">
                <el-col :span="11">
                  <el-row :gutter="20" v-for="(item,index) in (Object.keys(hqData).length == 0?5:hqData.sellList)" :key="index" class="deal-row">
                    <el-col :span="4" style="text-align: center" ><span class="title-trade title-trade-blob">{{item.text}}</span></el-col>
                    <el-col :span="14" style="text-align: center">
                      <div class="price-trade" style="float: left;color: #37AB61;" @click="clickPrice(item.price)">{{item.price!='0'&&item.price!='0.000'?item.price:'--'}}</div>
                      <div style="float: right;width: 65%;">
                        <div :style="{width:calculationWidth(item.num,Object.keys(hqData).length == 0?5:hqData.sellList)}" style="background-color: #37AB61;height: 3px;position: relative;top: 13px;"></div>
                      </div>
                    </el-col>
                    <!-- 将 dealNum(item.num) 改为 "item.num" -->
                    <el-col :span="6" style="text-align: center"><span class="title-trade">{{item.price!='0'&&item.price!='0.000'?item.num:'--'}}</span></el-col>
                  </el-row>
                </el-col>
                <el-col :span="2">
                  <el-divider direction="vertical" style="height: 100%;"></el-divider>
                </el-col>
                <el-col :span="11">
                  <el-row :gutter="20" v-for="(item,index) in (Object.keys(hqData).length == 0?5:hqData.buyList)" :key="index"  class="deal-row">
                    <el-col :span="4" style="text-align: center"><span class="title-trade title-trade-blob">{{item.text}}</span></el-col>
                    <el-col :span="14" style="text-align: center">
                      <div class="price-trade" style="float: left;" @click="clickPrice(item.price)">{{item.price!='0'&&item.price!='0.000'?item.price:'--'}}</div>
                      <div style="float: right;width: 65%;">
                        <div :style="{width:calculationWidth(item.num,Object.keys(hqData).length == 0?5:hqData.buyList)}" style="background-color: red;height: 3px;position: relative;top: 13px;"></div>
                      </div>
                    </el-col> 
                    <!-- 将 dealNum(item.num) 改为 "item.num" -->
                    <el-col :span="6" style="text-align: center"><span class="title-trade">{{item.price!='0'&&item.price!='0.000'?item.num:'--'}}</span></el-col>
                  </el-row>
                </el-col>
                
              </el-row>
            </div>
            <!-- <div style="height: 30px">
                <el-col :span="24" style="padding: 0" class="bg-dark">
                    <div class="bg-dark-div">
                        <el-row :gutter="20" class="deal-row">
                            
                        </el-row>
                    </div>
                </el-col>
            </div>
            <div style="padding: 10px 20px;margin: 10px 0">
                
            </div>
            <div style="height: 30px">
                <el-col :span="24" style="padding: 0" class="bg-dark">
                    <div class="bg-dark-div bg-dark-bottom">
                        <el-row :gutter="20" class="deal-row">
                            
                        </el-row>
                    </div>
                </el-col>
            </div> -->
        </div>
    </div>
</template>

<script>
    import {defineComponent, watch,onMounted,reactive,toRefs,onUnmounted} from "vue";
    import {props} from "./dealProps";
    import {getCodes,dealNums,dataRefrehs,clears,clickPrices} from "./dealMethods";
    import {dealData} from './dealData'
    export default defineComponent({
        name: "index",
        props:props,
        setup(props,ctx) {
            onMounted(()=>{
                getCode();
                dataRefreh()
            })
            watch(() => props.code, (val, prevVal) => {
              clear()
                    data.loading = true
                    getCodes(val,data,ctx)
                    dataRefreh()
                }
            )
            let data = reactive(dealData)
            let getCode = ()=>{getCodes(props.code,data,ctx)}
            let dealNum = (num)=>{return dealNums(num,props.code,props.codeType)}
            // 定时刷新数据
            let dataRefreh = () =>{dataRefrehs(props.code,data,ctx)}
            let clear = () =>{clears(data)};
            let clickPrice = (value) =>{clickPrices(value,ctx)};
            let calculationWidth = (a, array) => {
              let sum = 0
              if (array && array.length > 0) {
                array.forEach(item => {
                  sum = sum + Number(item.num)
                });
                let width = ''
                if ((Number(a) / Number(sum)).toFixed(2) * 100 < 1) {
                  width = '1%'
                } else {
                  width = (Number(a) / Number(sum)).toFixed(2) * 100 + '%'
                }
                return width
              } else {
                return 0
              }
              
            }
            onUnmounted(() => {clear()})
            return {
                ...toRefs(data),
                getCode,
                dealNum,
                dataRefreh,
                clickPrice,
                calculationWidth
            }
        }
    })
</script>

<style scoped lang="less">
    .deal-card {
        background: white;
        min-height: 200px;
        // border: 1px solid #EBEEF5;
        // border-radius: 10px;
        // box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        .bg-dark{
            width: 100%;
            .bg-dark-div{
                background: #E5E7E9;
                height: 30px;
            }
            .bg-dark-top{border-radius:10px 10px 0 0;}
            .bg-dark-bottom{border-radius: 0 0 10px 10px;}
        }
        .deal-row{
            line-height: 30px;
        }
        .title-trade{
            font-size: 13px;
        }
        .title-trade-blob{
            font-weight: 600;
        }
        .price-trade{
            cursor: pointer;
            font-size: 13px;
            color: red;
        }
    }
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #3B6EFB;
}
</style>
